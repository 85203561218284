
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /**
 * Since we can't switch to the app router yet, this is a fallback file for the pages router
 * See https://pxr-tech.atlassian.net/browse/PB-6306 for more details
 */
import { FC } from 'react';
import dynamic from 'next/dynamic';
import { PlatformID } from '@common/clients/api';
import { prepareProps } from '@web/_app/[contextHash]/prepareProps';
import { Route } from '@web/routing';
import { getPagesStaticPaths, getPagesStaticPropsWrapper } from '@web/routing/getPagesStaticPropsWrapper';
import { HomePage, Props } from '@web/templates/HomePage';
const ViHomePage = dynamic(() => import('@web/templates/HomePage/ViHomePage').then((mod) => mod.ViHomePage));
export const getStaticPaths = getPagesStaticPaths;
const getStaticProps = getPagesStaticPropsWrapper<Props>(prepareProps, Route.Homepage);
export const Home: FC<Props> = (props) => {
    return props.contextData.platform.id === PlatformID.VI ? (<ViHomePage {...props}/>) : (<HomePage {...props}/>);
};
export default Home;

    async function __Next_Translate__getStaticProps__1928aae361f__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[contextHash]/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1928aae361f__ as getStaticProps }
  